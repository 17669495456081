<template>
  <div>
    <GalleryAdmin />
  </div>
</template>

<script>
import GalleryAdmin from '@/components/Admin/Gallery/Gallery'

export default {
  name: 'Gallery',
  components: {
    GalleryAdmin,
  },
}
</script>

<style scoped></style>
