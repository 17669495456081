<template>
  <div>
    <v-container>
      <v-row no-gutters class="mb-6">
        <v-col
          cols="12"
          class="text-uppercase display-2 font-weight-black mb-4"
        >
          Gallery Page
        </v-col>
        <BaseLoading :loading="loading" />
        <v-col cols="12" sm="6">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-file-input
              :disabled="loading"
              v-model="files"
              :rules="fileRules"
              color="deep-purple accent-4"
              counter
              accept="image/png, image/jpeg, image/bmp"
              label="File input"
              multiple
              @change="uploadImageMultiple"
              placeholder="Select your files"
              prepend-icon="mdi-camera"
              :show-size="1000"
            >
              <template v-slot:selection="{ index, text }">
                <v-chip
                  v-if="index < 2"
                  color="deep-purple accent-4"
                  dark
                  label
                  small
                >
                  {{ text }}
                </v-chip>

                <span
                  v-else-if="index === 2"
                  class="overline grey--text text--darken-3 mx-2"
                >
                  +{{ files.length - 2 }} File(s)
                </span>
              </template>
            </v-file-input>
          </v-form>
        </v-col>
        <v-col cols="12" sm="6">
          <v-btn color="primary" class="text-uppercase" @click="saveData">
            Add Gallery
            <v-progress-circular
              v-show="loading"
              indeterminate
              :width="3"
              :size="20"
              class="ml-2"
              color="white"
            ></v-progress-circular>
          </v-btn>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="(item, i) in images"
          :key="i"
          class="d-flex child-flex"
          cols="3"
        >
          <v-img
            :src="path + item.image_id"
            :lazy-src="path + item.image_id"
            aspect-ratio="1"
            class="grey lighten-2"
          >
            <v-btn
              color="error"
              class="ma-2"
              fab
              small
              dark
              @click="deleteItem(item.id)"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import GalleryServices from '@/services/GalleryServices'
import { FormMixin } from '@/Mixins/FormMixin'

export default {
  name: 'Gallery',
  mixins: [FormMixin],
  data() {
    return {
      images: null,
      loading: false,
    }
  },
  computed: {
    path() {
      return this.$appConfig.serveConfig.SERVICE_URL_IMAGE
    },
  },
  async mounted() {
    await this.readData()
  },
  methods: {
    readData() {
      GalleryServices.getGallery()
        .then(res => {
          if (res.status === 200) {
            this.images = res.data
          }
        })
        .catch(err => {
          this.$swal.fire({
            icon: 'error',
            title: `${err.message}`,
          })
          if (err.response.status === 401) {
            this.removeToken()
            this.$router.push({ name: 'Admin' })
          }
        })
    },
    saveData() {
      if (this.$refs.form.validate()) {
        this.loading = !this.loading
        GalleryServices.createGallery(this.postFormData)
          .then(() => {
            this.$swal.fire({
              icon: 'success',
              title: 'News created successfully',
            })
            this.loading = !this.loading
            this.files = []
            this.postFormData.delete('image')
            this.readData()
          })
          .catch(err => {
            this.$swal.fire({
              icon: 'error',
              title: `${err.message}`,
            })
            this.loading = !this.loading
            this.files = []
            this.postFormData.delete('image')
            if (err.response.status === 401) {
              this.removeToken()
              this.$router.push({ name: 'Admin' })
            }
          })
      }
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!',
        })
        .then(result => {
          if (result.value) {
            GalleryServices.deleteGallery(id)
              .then(() => {
                this.$swal.fire({
                  icon: 'success',
                  title: 'News delete in successfully',
                })
                this.readData()
              })
              .catch(err => {
                this.$swal.fire({
                  icon: 'error',
                  title: `${err.message}`,
                })
                if (err.response.status === 401) {
                  this.removeToken()
                  this.$router.push({ name: 'Admin' })
                }
              })
          }
        })
    },
  },
}
</script>

<style scoped></style>
